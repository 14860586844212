import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { api_password, api_url, api_username } from './Data';


const Tour = () => {
    const [Tour, setTour] = useState([])


    useEffect(() => {
        axios.post(api_url + "studio_tours_list", { username: api_username, password: api_password }).then((res) => {
            setTour(res.data)
        })


    }, [])

    return (
        <>
            <section id='tour' className='my-5 mx-1 mx-md-5'>
                <div className="container-fluid p-0 col-md-8">
                    <div className="d-md-flex gap-4 m-0 justify-content-between">

                        {
                            Tour && Tour.map((item, i) => {
                                return <div className="black-op d-w-50 text-center mb-3 mb-lg-0" key={i}>
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item black-op">
                                            <h2 className="accordion-header black-op" id="headingOne">
                                                <button className="accordion-button d-block black-op fs-3 fw-bold text-white font-montserrat text-center" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} aria-expanded="true" aria-controls={`#collapse${i}`}>
                                                    {item.heading}
                                                </button>
                                            </h2>
                                            <div id={`collapse${i}`} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p className='text-white fs-5 mb-3 px-md-3 font-montserrat' dangerouslySetInnerHTML={{__html: item.description}}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }



                    </div>
                </div>
            </section>
        </>
    )
}

export default Tour