import { api_password, api_url, api_username, storage_url } from './Data';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
const Socials = () => {

  const [Socials, setSocials] = useState([])
  const [Icons, setIcons] = useState()

    useEffect(() => {
    axios.post(api_url+"review_list",{username : api_username, password : api_password}).then((res)=>{
        setSocials(res.data)
    });
    
    
}, [])

    useEffect(() => {
      axios.post(api_url+"social_icon_list",{username : api_username, password : api_password}).then((res)=>{
          setIcons(res.data)
      })
      
      
    }, [])

  return (
    <>
        <div className="bg-black text-center p-1 p-md-5" id='socials'>
        <div className="d-flex justify-content-center gap-4">
          {
            Icons && Icons.map((item)=>{
              return  <a href={`${item.link}`} className="p-color fs-1"><img src={`${storage_url+item.img}`} alt="" className='img-fluid social_icon w-75px aspect-datio-1'/></a>

            })
          }
          {/* <a href="#" className="p-color fs-1"><i class="fa-brands fa-square-facebook"></i></a> */}
          {/* <a href="#" className="p-color fs-1"><i class="fa-brands fa-x"></i></a> */}
        </div>
        <div className="row m-0 justify-content-center mt-4 mt-md-0">
          {
            Socials && Socials.map((item, index)=>{
              return  <div key={index} className="col-md-6 col-lg-4 text-center text-white p-md-5" >
              <h5 className='mb-0 mb-md-3 font-abel fs-4'>{item && item.description}</h5>
              <div className="d-flex justify-content-center gap-3 min-height-100px mt-2 mt-md-0">
                <img src={`${storage_url+item.img1}`} className={`img-fluid object-fit-contain w-75px aspect-ratio-1 ${index == 2 && 'w-115px'}`} alt="brand" />
                <img src={`${storage_url+item.img2}`} className={`img-fluid object-fit-contain w-75px aspect-ratio-1 ${index == 2 && 'w-115px'}`} alt="brand" />
              </div>
            </div>
            
            })
          }
        </div>
      </div>
    </>
  )
}

export default Socials