import React from 'react'

const Service_partial = ({Service,type,setshowBookingPopup}) => {
  return (
    <>
        <div className='servicePartial opacity mt-3'>
            <div className="container">
            {
                type == 'services' && 
                <div className="row">
                <div className="col-md-12 p-3 p-md-5">
                    <h2 className="fs-34 fw-light p-color text-center mb-md-5">{Service.heading}</h2>
                    <div className='text-white' dangerouslySetInnerHTML={{__html:Service.description}}></div>
                    <div className='d-md-flex justify-content-center gap-5'>
                        {
                            Service.heading.toLowerCase().includes('production') ? 
                            <>
                                <a href={`javascript:;`} onClick={()=>{setshowBookingPopup(true)}} className='text-uppercase d-block text-white text-decoration-none text-center p-3 bg-yellow fw-bold'>Book Session <br className='d-none d-md-block'/> W/Producer</a>
                                <a href={`http://brewerybeats.com/`} className='text-uppercase d-block text-white text-decoration-none text-center p-3 bg-yellow px-5 fw-bold mt-2 mt-md-0' target='_blank'>Buy<br className='d-none d-md-block'/> Beats</a>
                            </>
                            :
                            <a href={`${Service.link}`} className='fs-24 text-uppercase text-white text-decoration-none text-center p-3 bg-yellow fs-md-17' target='_blank'>Book Session <br className='d-none d-md-block'/> W/Engineer</a>
                        }
                    </div>
                </div>
            </div>
            }
            {
                type == "gear" && 
                <div className="row">
                <div className="col-md-12 p-3 p-md-5">
                    <div className="wrap text-center">
                    <h2 className="fw-bold text-white text-uppercase">{Service.category.name}</h2>
                    <h4 className="fw-bold text-white text-uppercase">{Service.name}</h4>
                    </div>
                    <p className='text-white text-center d-column-3 ws-breakspaces'>{Service.description}</p>                   
                </div>
            </div>
            }
            </div>
        </div>
    </>
  )
}

export default Service_partial