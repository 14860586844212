import React, { useState } from "react";
import { assets_url } from "../Config";
import { Engineer_tabs } from './partials/Engineer_tabs';


const Engineers = () => {
    
    const [location, setlocation] = useState('New York')
    const [display_button, setdisplay_button] = useState(true)
   
    return (

    <>
        <div className={`EngineerSection`} id="engineers">
            <div className={`container ${display_button ? '' : 'd-none'}`}>
                <div className="container-fluid py-4">
                    <div className="row m-0">
                        <div className="col-md-12 text-center mb-3">
                            <h4 className='fw-light p-color fs-48 font-abel mb-0 text-uppercase'>Engineers & producers</h4>
                        </div>
                        <div className='text-white d-flex justify-content-around switchButtons'>
                            <button className={`btn btn-primary fs-6 py-3 border-0  ${location == "New York" && 'active'}`} onClick={()=>setlocation('New York')}>NEW YORK</button>
                            <button className={`btn btn-primary fs-6 py-3 border-0 ${location == "Los Angeles" && 'active'}`} onClick={()=>{setlocation('Los Angeles')}}>LOS ANGELES</button>
                        </div>
                    </div>
                   
                </div>
            </div>
            
            {location == "New York" ? <Engineer_tabs cat={location} display = {setdisplay_button}/> : <Engineer_tabs cat={location} display = {setdisplay_button}/>}
            
        </div>
    </>
  )
}

export default Engineers