import React, { useEffect, useState } from 'react'
import { assets_url } from "../../Config";
import { api_password, api_url, api_username, storage_url } from '../Data';
const Single_engineer = ({ data, display, setview }) => {


    return (
        <>
            <div className="img-wrap overflow-hidden h-100">
                <img src={`${storage_url + data.img}`} alt="" className='img-fluid w-100 h-100 object-fit-cover' />
            </div>
            <div className="position-absolute top-0 h-100 engineerScroll">
                <div className="container-fluid ">
                    <div className="row m-0">
                        <div className="col-md-6 p-2 p-md-5">

                            <h3 className='fw-light p-color fs-48 font-abel mb-0 text-uppercase mt-4'>{data.designation}</h3>
                            <h2 className='fw-900 text-white fs-96 mt-0 font-montserrat'>{data.fullname}</h2>
                            <h4 className='fw-light p-color fs-24 my-2 my-md-5'>{data.clients}</h4>
                            <p className='text-white fw-bold'> {data.description}</p>
                            {data.links && <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src={data.links}></iframe>}
                                
                            <div className="video">

                            </div>

                        </div>
                        <div className="col-md-6 ms-auto">
                            {/* {display && <h2 className='fw-900 text-white fs-96 mt-0 font-montserrat text-end' onClick={()=>{display(true); setview(false)}} ><a href="javascript:void(0)" className='text-decoration-none text-white fs-40'><span className='p-color rounded-circle fs-18'><i class="fa-solid fa-arrow-left"></i></span> BACK TO ENGINEERS</a></h2>} */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Single_engineer