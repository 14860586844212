import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { api_password, api_url, api_username } from '../Data';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [Footer, setFooter] = useState([])
    useEffect(() => {
        axios.post(api_url+"footer_list",{username : api_username, password : api_password}).then((res)=>{
            setFooter(res.data[0])
        })
        
        
    }, [])

  return (
    <>
        <section id='footer' className='py-5'>
            <div className="row m-0">
                <div className="col-md-12 text-center my-auto">
                    <a href={`call: ${Footer.contact}`} className='fs-6 text-light pe-3 text-uppercase text-decoration-none border-end'>Call Us</a>
                    {/* <span className='border-end p-2'></span> */}
                    <a href={`mailto:${Footer.email}`} className='fs-6 text-light ms-3 text-uppercase text-decoration-none'>email Us</a>
                </div>
                <div className="col-md-12 text-center pt-2 my-auto">
                    <Link to="/terms-and-conditions" className='fs-6 text-light pe-3 text-uppercase text-decoration-none border-end'>Terms & Condition</Link>
                    {/* <span className='border-end p-2'></span> */}
                    <Link to="/privacy-policy" className='fs-6 text-light ms-3 text-uppercase text-decoration-none'>Privacy Policy</Link>
                </div>
                <div className='col-md-12 text-center py-2'>
                    <p className='fw-bolder fs-6 text-light'>{Footer && Footer.footer_text}</p>
                </div>
            </div>

        </section>
    </>
  )
}

export default Footer