import React, { useEffect, useState } from 'react';
import { api_password, api_url, api_username, storage_url } from './Data';
import { assets_url } from '../Config';
import Service_partial from './partials/Service_partial';
import axios from 'axios';

const Service = ({setshowBookingPopup}) => {
    const [services_name, setservices_name] = useState('Services')
    const [Service_item, setService_item] = useState('')
    const [data, setdata] = useState([])
    const [type, settype] = useState('services')
    const [Service, setService] = useState([])
    const [location, setlocation] = useState(1)
    const [loader, setloader] = useState(false)

    useEffect(() => {
        setloader(true)
        if (type == 'gear') {
            axios.post(api_url + "studios_list", { username: api_username, password: api_password }).then((res) => {
                setdata(res.data)
            })

        }
        if (type == 'floorPlan') {
            axios.post(api_url + "studios_list", { username: api_username, password: api_password }).then((res) => {
                setdata(res.data)
            })
        }
        if (type == 'services') {
            axios.post(api_url + "services_list", { username: api_username, password: api_password }).then((res) => {
                setService(res.data)
            })
        }
        if (type == 'history') {
            axios.post(api_url + "history_list", { username: api_username, password: api_password }).then((res) => {
                setdata(res.data)
            })
        }
    }, [type])
    useEffect(() => {
        setloader(false)
    }, [data])


    return (
        <>
            <section className='serviceSection d-flex align-items-start py-3' id='services'>
                <div className="container col-md-8">
                    <div className="container-fluid ">
                        <div className="row m-0 mt-3">
                            <div className="col-6 col-lg-3">
                                <button class={`btn btn-secondary text-uppercase rounded-0 my-1 my-lg-0 border-0 p-4 w-100 ${services_name == 'Services' && 'active p-color'}`} onClick={() => { setService_item(''); settype('services'); setservices_name('Services') }}>Services</button>
                            </div>
                            <div className="col-6 col-lg-3">
                                <button class={`btn btn-secondary text-uppercase rounded-0 my-1 my-lg-0 border-0 p-4 w-100 ${services_name == 'gear' && 'active p-color'}`} onClick={() => { setService_item(''); settype('gear'); setservices_name('gear') }}>Gear</button>
                            </div>
                            <div className="col-6 col-lg-3">
                                <button class={`btn btn-secondary text-uppercase rounded-0 my-1 my-lg-0 text-nowrap border-0 p-4 w-100 ${services_name == 'floorPlan' && 'active p-color'}`} onClick={() => { setService_item(''); settype('floorPlan'); setservices_name('floorPlan'); }}>Floor Plan</button>
                            </div>
                            <div className="col-6 col-lg-3">
                                <button class={`btn btn-secondary text-uppercase rounded-0 my-1 my-lg-0 border-0 p-4 w-100 ${services_name == 'history' && 'active p-color'}`} onClick={() => { setService_item(''); settype('history'); setservices_name('history'); }}>History</button>
                            </div>

                            {
                                type == 'services' && Service_item == '' && Service && Service.map((item) => {
                                    return (
                                        <div className="col-md-6 d-flex justify-content-center align-items-center flex-column position-relative cursor-pointer filter-white" key={item.id} onClick={() => { setService_item(item) }}>
                                            <div className='w-100 wrap overflow-hidden position-relative my-1 my-md-3 p-md-3 px-md-4'>
                                                <div className='opacity w-100 d-flex justify-content-center align-items-center flex-column py-2 py-md-4 '>
                                                    <img src={`${storage_url + item.img}`} alt="" className='img-fluid w-100px aspect-datio-1 object-fit-contain' />
                                                    <h2 className='text-white text-uppercase fs-20 fw-bold mt-2 pt-md-3 p-color text-center'>{item.heading}</h2>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                type == 'gear' && data && Service_item == '' &&
                                <>
                                    <div className='w-100 wrap overflow-hidden position-relative my-2'>
                                        <div className='opacity w-100 d-flex justify-content-center align-items-center flex-column  p-5 min-height-350'>
                                            {loader ? <div className='text-white fw-bold fs-3 loading'></div> : <div className="row text-center w-100">
                                                <div className="col-md-6">
                                                    <ul className='list-unstyled'>
                                                        <li className='p-color fw-bold fs-2 mb-3'>NEW YORK</li>
                                                        {
                                                            data && data.filter((item) => item?.category?.id == 1 && item.gear_status == 1).map((item, ind) => {
                                                                return <li className='fw-bold text-white fs-3 mb-2 cursor-pointer' onClick={() => { setService_item(item, type) }} key={ind}>{item.name}</li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul className='list-unstyled'>
                                                        <li className='p-color fw-bold fs-2 mb-3'>LOS ANGELES</li>
                                                        {
                                                            data && data.filter((item) => item?.category?.id == 2 && item.gear_status == 1).map((item, ind) => {
                                                                return <li className='fw-bold text-white fs-3 mb-2 cursor-pointer' onClick={() => { setService_item(item, type) }} key={ind}>{item.name}</li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                type == 'floorPlan' && data && Service_item == '' &&
                                <>
                                    <div className='w-100 wrap overflow-hidden position-relative my-2'>
                                        <div className='opacity w-100 d-flex justify-content-center align-items-center flex-column  p-md-5 min-height-350'>
                                            {loader ? <div className='text-white fw-bold fs-3 loading'>Loading...</div> :
                                                <div className="row text-center w-100">
                                                    <div className="col-md-12 servicePartial">
                                                        <div className='text-white d-md-flex justify-content-around'>
                                                            <button className={`btn btn-primary border-0  ${location == 1 && 'active'}`} onClick={() => setlocation(1)}>NEW YORK</button>
                                                            <button className={`btn btn-primary border-0 ${location == 2 && 'active'}`} onClick={() => { setlocation(2) }}>LOS ANGELES</button>
                                                        </div>
                                                        {location == 1 && <img src={`${storage_url + data.filter(item => item?.category?.id == 1)[0]?.category.floor_plan}`} alt="floor plan" className='img-fluid w-60' />}
                                                        {location == 2 && <img src={`${storage_url + data.filter(item => item?.category?.id == 2)[0]?.category.floor_plan}`} alt="floor plan" className='img-fluid w-75' />}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                type == 'history' && Service_item == '' &&
                                <>
                                    <div className='w-100 wrap overflow-hidden position-relative my-2'>
                                        <div className='opacity w-100 d-flex justify-content-center align-items-center flex-column p-3 p-md-5 min-height-350'>
                                            {loader ? <div className='text-white fw-bold fs-3 loading'></div> :<div className="row text-center w-100">
                                                <div className="col-md-12 servicePartial">
                                                    <h2 className="fw-bold p-color">{data[0]?.heading}</h2>
                                                    <p className='text-white line-height-50'>{data[0]?.description}</p>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                        {Service_item && <Service_partial Service={Service_item} type={type} setshowBookingPopup={setshowBookingPopup} />}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Service