import React, { useEffect, useRef, useState } from 'react'
import { assets_url } from "../../Config";
import axios from 'axios';
import { api_password, api_url, api_username, storage_url } from '../Data';
import Single_engineer from './Single_engineer';
import Slider from "react-slick";

export const Engineer_tabs = ({ cat, display }) => {


    // Define custom arrow components
    // const PrevArrow = (props) => {
    //     const { className, style, onClick } = props;
    //     return (
    //         <div className={className + " slick-arroww slick-prev btn-prev text-danger"} onClick={onClick}>
    //             <button>Prev</button>
    //         </div>
    //     );
    // };

    // const NextArrow = (props) => {
    //     const { className, style, onClick } = props;
    //     return (
    //         <div className={className + " slick-arroww slick-next btn-next text-danger"} onClick={onClick}>
    //             Next
    //         </div>
    //     );
    // };
    const sliderRef = useRef(null);
    const goToPrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const goToNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [employees, setemployees] = useState([])
    const [displayCount, setDisplayCount] = useState(10);


    const filteredEmployees = employees.filter(item => item.category.name === cat);
    const visibleEmployees = filteredEmployees.slice(0, displayCount);

    const handleLoadMore = () => {
        setDisplayCount(prevCount => prevCount + 10);
    };

    useEffect(() => {
        loadApi()
    }, [])
    const loadApi = () => {
        axios.post(api_url + "employees_list", { username: api_username, password: api_password }).then((res) => {
            setemployees(res.data)
        })
    }
    const [view, setview] = useState('')
    function view_render(data) {
        // display(false)
        setview(data)
    }

    let rows = [];
    useEffect(() => {
        rows = []
    }, [cat])

    if (cat == "New York") {
        for (let i = 0; i < filteredEmployees.length; i += 20) {
            rows.push(
                <div className="row col-md-10 mx-auto d-flex justify-content-center" key={i}>
                    {filteredEmployees.slice(i, i + 20).map((item, index) => (
                        <div className="col-6 col-md-6 d-w-20 p-2 position-relative text-white my-1 cursor-pointer card-hover" key={index} onClick={() => view_render(item)}>
                            <img src={`${storage_url + item.img}`} alt="" className="img-fluid" />
                            <div className="position-absolute bottom-0 ms-3">
                                <h2 className="fs-12px fw-bold">{item.fullname}</h2>
                                <p className="fs-12px fw-lighter">{item.designation}</p>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
    }
    else {
        for (let i = 0; i < filteredEmployees.length; i++) {
            rows.push(
                <div className="row d-flex" key={i}>
                    {filteredEmployees.map((item, index) => (
                        <div className="col-md-6 d-w-20 position-relative text-white my-1 cursor-pointer card-hover" key={index} onClick={() => view_render(item)}>
                            <img src={`${storage_url + item.img}`} alt="" className="img-fluid" />
                            <div className="position-absolute bottom-0 ms-3">
                                <h2 className="fs-20 fw-bold fs-md-15">{item.fullname}</h2>
                                <p className="fs-20 fw-lighter fs-md-15">{item.designation}</p>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
    }



    return (
        <>
            <div className="container">
                <div className="wrap position-relative d-none d-md-block">
                    {cat == "New York" &&
                    <>
                    <Slider {...settings} ref={sliderRef}>
                        {rows}
                    </Slider>
                    {/* <div className="d-flex justify-content-center gap-2">
                        <button onClick={goToPrev} className='btn opacity text-light aspect-datio-1 rounded-circle fs-5'><i class="fa-solid fa-arrow-left-long"></i></button>
                        <button onClick={goToNext} className='btn opacity text-light aspect-datio-1 rounded-circle fs-5'><i class="fa-solid fa-arrow-right-long"></i></button>
                    </div> */}
                    </>
                    }
                    {cat == "Los Angeles" && <div className="row justify-content-center">
                        {filteredEmployees.map((item, index) => (
                            <div className="col-md-6 d-w-20 position-relative text-white my-2 cursor-pointer card-hover" key={index} onClick={() => view_render(item)}>
                                <img src={`${storage_url + item.img}`} alt="" className="img-fluid" />
                                <div className="position-absolute bottom-0 ms-3">
                                    <h2 className="fs-12px fw-bold">{item.fullname}</h2>
                                    <p className="fs-12px fw-lighter">{item.designation}</p>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
                <div className="wrap position-relative d-md-none engineer-cards-mobile">
                    {cat == "New York" && <div className="row justify-content-center">
                        {filteredEmployees.map((item, index) => (
                            <div className="col-6 col-lg-3 position-relative text-white my-2 cursor-pointer card-hover" key={index} onClick={() => view_render(item)}>
                                <img src={`${storage_url + item.img}`} alt="" className="img-fluid" />
                                <div className="position-absolute bottom-0 ms-3">
                                    <h2 className="fs-20 fw-bold fs-md-15">{item.fullname}</h2>
                                    <p className="fs-20 fw-lighter fs-md-15">{item.designation}</p>
                                </div>
                            </div>
                        ))}
                    </div>}
                    {cat == "Los Angeles" && <div className="row justify-content-center">
                        {filteredEmployees.map((item, index) => (
                            <div className="col-6 col-lg-3 position-relative text-white my-2 cursor-pointer card-hover" key={index} onClick={() => view_render(item)}>
                                <img src={`${storage_url + item.img}`} alt="" className="img-fluid" />
                                <div className="position-absolute bottom-0 ms-3">
                                    <h2 className="fs-20 fw-bold fs-md-15">{item.fullname}</h2>
                                    <p className="fs-20 fw-lighter fs-md-15">{item.designation}</p>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
            {/* <div className={`container ${view == '' ? '' : 'd-none'}`}>
                <div className="row empList m-0 justify-content-center">
                    {visibleEmployees && visibleEmployees.map((item, index) => (
                        <div className="col-md-6 col-lg-3 position-relative text-white my-2" key={index} onClick={() => view_render(item)}>
                            <img src={`${storage_url + item.img}`} alt="" className="img-fluid" />
                            <div className="position-absolute bottom-0 ms-3">
                                <h2 className="fs-20 fw-bold fs-md-15">{item.fullname}</h2>
                                <p className="fs-20 fw-lighter fs-md-15">{item.designation}</p>
                            </div>
                        </div>
                    ))}
                </div>
                {displayCount < filteredEmployees.length && (
                    <div className="text-center mt-3 btnLoad">
                        <button className="btn btn-warning p-2 fw-bold mb-3" onClick={handleLoadMore}>Load More</button>
                    </div>
                )}
            </div> */}
            {view && <div className="popup animate__animated animate__bounceIn animate__slow">
                <div className="popup-wrap">
                    <div className="btn_close fs-1 cursor-pointer text-black position-absolute end-0 top-0 m-3 z-index-max" onClick={() => { setview('') }}><i class="fa-solid fa-circle-xmark"></i></div>
                    <Single_engineer data={view} display={display} setview={setview} />
                </div>
            </div>}
        </>
    )
}
