import { useState } from "react";


import { assets_url } from "./Config";
import HeroSection from "./components/HeroSection";
import Footer from "./components/partials/Footer";
import { Link } from "react-router-dom";

function PageNotFound() {
  const style = {
    
  }
  return (
    <div className="App pageNotFound">  
    <div className="wrap w-100 h-100vh d-grid place-content-center text-center">
      <h1 className="text-white fw-bold m-0">4<span className="p-color">0</span>4</h1>
      <h2 className="text-white fw-bold m-0 fs-1">Page <span className="text-danger">Not</span> Found</h2>
      <Link className="btn btn-outline-warning mt-5" to="/">Back to Home</Link>
      </div>    
    </div>
  );
}

export default PageNotFound;
