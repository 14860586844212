import Navbar from './partials/Navbar'
import { assets_url } from '../Config'
import { api_password, api_url, api_username, storage_url } from './Data';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from "react-slick";

const HeroSection = ({setshowBookingPopup}) => {

  const [Hero, setHero] = useState([])

  useEffect(() => {
    axios.post(api_url + "breadcrump_list", { username: api_username, password: api_password }).then((res) => {
      setHero(res.data)
    })

  }, [])
  
  var settings = {
    infinite: true,
    autoplay:true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false
  };

  return (
    <>
        <Navbar setshowBookingPopup={setshowBookingPopup}/>
      <Slider {...settings}>
        {
          Hero && Hero.map((item)=>{
            return(
              <section className="heroSection d-grid place-content-center">
                <img src={`${storage_url+item.img}`} className='position-absolute object-fit-cover w-100vw h-100vh' style={{zIndex:-50}} alt="" />
                <div className="wrap mb-md-5 text-center">
                  <h1 className="fw-900 text-white text-uppercase m-0">{item && item.heading}</h1>
                  <h2 className="p-color text-center font-abel">{item && item.description}</h2>
                </div>
                <button onClick={()=>{setshowBookingPopup(true)}} className="btn btn-outline-warning rounded-0 text-white fs-5 w-fit mx-auto px-3 d-flex align-items-center gap-2 mt-5 text-uppercase p-3 font-montserrat fw-bold"><img src={`${assets_url}/images/icons/booking.svg`} alt="icon" className='img-fluid me-3' />Book a session</button>
              </section>
            )
          })
        }      
      </Slider>      
    </>
  )
}

export default HeroSection
