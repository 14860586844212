import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_password, api_url, api_username, storage_url } from "./components/Data";
import {Helmet}  from 'react-helmet';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsCondition from "./TermsCondition";
import PageNotFound from "./PageNotFound";

function App() {

  const [metaData, setMetaData] = useState([]);

  useEffect(() => {
      axios.post(api_url+"header_list",{username : api_username, password : api_password}).then((res)=>{
          setMetaData(res.data[0])
      })   
      
  }, [])


  return (
    <>
    <Helmet>
      <title>{metaData.meta_title}</title>
      <meta name="description" content={metaData.meta_description} />
    </Helmet>
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy isHome={true} />} />
        <Route path="/terms-and-conditions" element={<TermsCondition isHome={true}/>} />
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
    </BrowserRouter>
    </div>
    </>
  );
}

export default App;
