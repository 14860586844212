import React, { useEffect, useState } from 'react'
import Navbar from './components/partials/Navbar'
import axios from 'axios'
import { api_password, api_url, api_username } from './components/Data'
import Footer from './components/partials/Footer'

const PrivacyPolicy = ({isHome}) => {
    const [ppContent, setppContent] = useState('')
    useEffect(() => {
        axios.post(api_url + "privacy_policy", { username: api_username, password: api_password }).then((res) => {
            setppContent(res.data)
          })
    }, [])
    
    return (
        <div className="bg-blackk">
            
            <div className="innerPage">
                <div className="nav-wrapp min-height-200">
                    <Navbar isHome={isHome}/>
                </div>
                <div className="container text-center pb-5">
                <h1 className='text-white fw-bold'>PRIVACY POLICY</h1>
                    <div className='text-white' dangerouslySetInnerHTML={{__html:ppContent.description}}></div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PrivacyPolicy
