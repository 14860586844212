import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { api_password, api_url, api_username } from './Data';
import Slider from 'react-slick';


const Maps = () => {

    const [Maps, setMaps] = useState([])


    useEffect(() => {
        axios.post(api_url + "categories_list", { username: api_username, password: api_password }).then((res) => {
            setMaps(res.data)
        })
    }, [])
    var settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        autoplay:false,
        arrows:false
    };

    return (
        <>
            <section id='Maps' className='my-5 mx-2 mx-md-5'>
                <div className="row m-0 d-none d-md-flex">
                    {
                        Maps && Maps.map((item, i) => {
                            return <div className="col-md-6 mb-3 mb-md-0">
                                <div className='text-center'>
                                    <h3 className='text-white fs-34 fw-bold'>
                                        {item.name}
                                    </h3>
                                    <iframe src={item.location} className="height-500" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="row m-0 d-md-none">
                    <Slider {...settings}>
                    {
                        Maps && Maps.map((item, i) => {
                            return <div className="col-md-6 mb-3 mb-md-0">
                                <div className='text-center'>
                                    <h3 className='text-white fs-34 fw-bold'>
                                        {item.name}
                                    </h3>
                                    <iframe src={item.location} className="height-500" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        })
                    }
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Maps