import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { api_password, api_url, api_username } from './Data';
import SimplyBookWidget from './BookingFrame';
import BookingPopup from './BookingPopup';
import Slider from "react-slick";
const Booking = ({ setshowBookingPopup }) => {


    const [Booking, setBooking] = useState([])
    const [Service, setService] = useState([])
    const [headingData, setheadingData] = useState()
    const [showBooking, setshowBooking] = useState(false)
    useEffect(() => {
        axios.post(api_url + "plans_list", { username: api_username, password: api_password }).then((res) => {
            setBooking(res.data)
        })

        axios.post(api_url + "headings_services", { username: api_username, password: api_password }).then((res) => {
            setheadingData(res.data)
        })
    }, [])

    useEffect(() => {
        axios.post(api_url + "other_services_list", { username: api_username, password: api_password }).then((res) => {
            setService(res.data)
        })


    }, [])

    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
    };
    function bs_nextClick() {
        document.querySelector('.bookingSlider button.slick-arrow.slick-next').click()
    }
    function bs_prevClick() {
        document.querySelector('.bookingSlider button.slick-arrow.slick-prev').click()
    }
    return (
        <>
            <section className='booking p-3 p-md-5' id='rates'>
                <div className="opacity py-3 col-md-10 mx-auto p-3">
                    <div className="container">
                                <h2 className='fw-light mt-3 text-center p-color font-abel mb-3 text-uppercase'>Rates</h2>
                        <div className="row justify-content-center forDesktop d-none d-md-flex align-items-start">                            
                            <div className="col-lg-3 align-items-center justify-content-center d-none d-md-flex">
                                <ul className="list-unstyled text-white">
                                    <li style={{color:'transparent'}}>heading</li>
                                    <li className='fw-bolder font-montserrat fs-md-17 text-center lh-1-8'>{headingData && headingData[0].rate_1}</li>
                                    <li className='fw-bolder font-montserrat fs-md-17 text-center lh-1-8'>{headingData && headingData[0].rate_2}</li>
                                    <li className='fw-bolder font-montserrat fs-md-17 text-center lh-1-8'>{headingData && headingData[0].rate_3}</li>
                                </ul>
                            </div>                            
                            {
                                Booking && Booking.map((item) => {
                                    return <div className="col-md-4 col-lg-3 mb-4 mb-md-0 px-3">
                                        <ul className="list-unstyled text-white text-center">
                                            <li className='fw-bolder font-montserrat '>{item.heading}</li>
                                            <li className='fs-md-17 lh-1-8'>{item.per_hour}</li>
                                            <li className='fs-md-17 lh-1-8'>{item.half_day}</li>
                                            <li className='fs-md-17 lh-1-8'>{item.full_day}</li>

                                        </ul>
                                        <button onClick={() => { setshowBookingPopup(true) }} target='_blank' className='fs-6 border-0 p-2 py-md-3 text-uppercase text-white text-decoration-none text-center d-block bg-yellow'>Book Session {item.heading}</button>
                                    </div>
                                })

                            }  
                        </div>
                        <div className="forMobile row bookingSlider d-md-none position-relative">
                            <button onClick={bs_prevClick } className="w-fit border-0 position-absolute bg-transparent text-white start-0 top-50 translate-middle-y z-index-max btnLeft"><i class="fs-2 fa-solid fa-angles-left"></i></button>
                            <button onClick={bs_nextClick} className="w-fit border-0 position-absolute bg-transparent text-white end-0 top-50 translate-middle-y z-index-max btnRight"><i class="fs-2 fa-solid fa-angles-right"></i></button>
                                <Slider {...settings}>
                            {
                                Booking && Booking.map((item) => {
                                    return <div className="col-md-4 col-lg-3 mb-4 mb-md-0 px-4">
                                        <ul className="list-unstyled text-white text-center">
                                            <li className='fw-bolder fs-4 font-montserrat '>{item.heading}</li>
                                            <li className='mt-2'>{headingData && headingData[0].rate_1}</li>
                                            <li className='fs-2 fw-bold'>{item.per_hour}</li>
                                            <li className='mt-2'>{headingData && headingData[0].rate_2}</li>
                                            <li className='fs-2 fw-bold'>{item.half_day}</li>
                                            <li className='mt-2'>{headingData && headingData[0].rate_3}</li>
                                            <li className='fs-2 fw-bold'>{item.full_day}</li>
                                        </ul>
                                        <button onClick={() => { setshowBookingPopup(true) }} target='_blank' className='small border-0 p-2 mx-auto py-3 py-md-3 text-uppercase text-white text-decoration-none text-center d-block bg-yellow'>Book Session {item.heading}</button>
                                    </div>
                                })

                            }
                            </Slider>
                            </div>
                        <div className="row d-none d-md-flex">
                            <div className="col-md-12 text-center my-4">
                                <h2 className='fw-light p-color  font-abel text-uppercase'>Other Services</h2>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className='fs-md-17 text-uppercase text-white text-decoration-none text-center d-block bg-op-yellow border'>
                                    <a target='_blank' href={headingData && headingData[0].link} className='m-0 text-uppercase py-3 small text-white text-decoration-none d-block'>{headingData && headingData[0].top_heading_other_service_card}</a>
                                </div>
                                <div className='d-flex align-items-center justify-content-center min-height-200 text-center'>
                                    <h2 className='fs-20 text-white fw-bolder text-center col-8'>{headingData && headingData[0].heading_other_service}</h2>
                                </div>
                            </div>
                            {
                                Service && Service.map((item) => {
                                    return <div className="col-md-6 col-lg-3 px-3">
                                        <div className='fs-md-17 text-uppercase text-white text-decoration-none text-center d-block bg-op-yellow border'>
                                            <a href={`${item.heading_link}`} className='m-0 text-uppercase py-3 small text-white text-decoration-none d-block' target='_blank'>{item.heading}</a>
                                        </div>
                                        <a href={item.link} target='_blank'>
                                            <div className='opacity px-2 mt-2 d-grid place-content-center h-180px border-on-hover'>
                                                <ul className="list-unstyled text-center text-white ">
                                                    <li className='fw-bold small'>{item.description}</li>
                                                </ul>
                                            </div>
                                        </a>
                                    </div>
                                })
                            }

                        </div>
                        <div className="row d-md-none">
                            <div className="col-md-12 text-center my-4">
                                <h2 className='fw-light p-color  font-abel text-uppercase'>Other Services</h2>
                            </div>                            
                            <div className="col-md-6 col-lg-3">
                                <div className='fs-md-17 text-uppercase text-white text-decoration-none text-center d-block bg-op-yellow border'>
                                    <a href={headingData && headingData[0].link} className='m-0 text-uppercase py-3 small text-white text-decoration-none d-block'>{headingData && headingData[0].top_heading_other_service_card}</a>
                                </div>
                                {
                                Service && Service.map((item) => {
                                    return <div className="col-md-6 col-lg-3 mt-2">
                                        <div className='fs-md-17 text-uppercase text-white text-decoration-none text-center d-block bg-op-yellow border'>
                                            <a href={item.heading_link} className='m-0 text-uppercase py-3 small text-white text-decoration-none d-block' target='_blank'>{item.heading}</a>
                                        </div>                                        
                                    </div>
                                })
                            }
                                <div className='d-flex align-items-center justify-content-center mt-3 text-center'>
                                    <h2 className='fs-20 text-white fw-bolder text-center'>{headingData && headingData[0].heading_other_service}</h2>
                                </div>
                            </div>
                            {
                                Service && Service.map((item) => {
                                    return <div className="col-md-6 col-lg-3 mt-2">                                        
                                        <a href={item.link} target='_blank'>
                                            <div className='opacity p-2 border-on-hover'>
                                                <ul className="list-unstyled text-center text-white ">
                                                    <li className='fw-bold small'>{item.description}</li>
                                                </ul>
                                            </div>
                                        </a>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Booking