import React from 'react'

const BookingPopup = ({setshowBookingPopup}) => {
    return (
        <div>
            <div className="popup opacity">
                {/* <SimplyBookWidget/> */}
                <div className="popup-wrap w-60">
                    <div className="btn_close fs-1 cursor-pointer text-black position-absolute end-0 top-0 m-3 z-index-max" onClick={() => { setshowBookingPopup(false) }}><i class="fa-solid fa-circle-xmark"></i></div>
                    <iframe className='w-100 h-100' src="https://brewery.simplybook.me/v2/?widget-type=iframe&theme=air&theme=air&timeline=flexible_provider&datepicker=top_calendar&_ga=GA1.1.1097431461.1705489568#book" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    )
}

export default BookingPopup
