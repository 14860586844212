import React, { useEffect } from 'react';

const SimplyBookWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//widget.simplybook.me/v2/widget/widget.js';
    script.async = true;
    document.getElementById('simplybook-widget-container').appendChild(script);

    script.onload = () => {
      const widget = new window.SimplybookWidget({
        widget_type: 'iframe',
        url: 'https://brewery.simplybook.me',
        theme: 'air',
        theme_settings: {
          timeline_hide_unavailable: '1',
          hide_past_days: '0',
          timeline_show_end_time: '0',
          timeline_modern_display: 'as_slots',
          sb_base_color: '#e0bb7b',
          display_item_mode: 'list',
          booking_nav_bg_color: '#e0bb7b',
          body_bg_color: '#ffffff',
          sb_review_image: '',
          dark_font_color: '#474747',
          light_font_color: '#ffffff',
          btn_color_1: '#e0bb7b',
          sb_company_label_color: '#ffffff',
          hide_img_mode: '0',
          show_sidebar: '1',
          sb_busy: '#c7b3b3',
          sb_available: '#d6ebff'
        },
        timeline: 'flexible_provider',
        datepicker: 'top_calendar',
        is_rtl: false,
        app_config: {
          clear_session: 0,
          allow_switch_to_ada: 0,
          predefined: []
        }
      });
    };

    return () => {
      document.getElementById('simplybook-widget-container').removeChild(script);
    };
  }, []);

  return <div id="simplybook-widget-container"></div>;
};

export default SimplyBookWidget;
