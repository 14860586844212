import React, { useEffect, useState } from 'react';
import { assets_url } from '../../Config';
import axios from 'axios';
import { api_password, api_url, api_username, storage_url } from '../Data';
import { Link } from 'react-router-dom';



const Navbar = ({isHome,setshowBookingPopup}) => {

  const [Nav, setNav] = useState()
  const [navItems, setnavItems] = useState()

  useEffect(() => {
    axios.post(api_url + "header_list", { username: api_username, password: api_password }).then((res) => {
      setNav(res.data[0])
      setnavItems(JSON.parse(res.data[0].tabs))
    })
  }, [])

  const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    
    

  return (
    <div className={`${isSticky ? 'position-fixed bg-black' : 'position-absolute'} transition w-100 z-index-max`}>
      <nav className="navbar navbar-expand-lg navbar-light border-bottom border-secondary py-1">
        <div className="container-fluid flex-md-nowrap">
          <Link to="/" className='navbar-brand me-0 text-decoration-none d-flex align-items-center gap-1 gap-md-2'><img src={`${Nav && storage_url + Nav.logo}`} alt="" className='w-45px' />          
          <div className="wrap text-white">
            <h6 className=" m-0 fw-bold">{Nav && Nav.heading}</h6>
            <p className=" m-0 small fw-light fs-12px">NEW YORK CITY | LOS ANGELES </p>
            <p className=" m-0 small fw-light fs-12px">{Nav && Nav.contact}</p>
          </div>
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fa-solid fa-bars navbar-toggler-icon text-white fs-2 h-auto"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 pb-4 pb-md-0">
              {isHome && <li className="align-self-center nav-item btn text-uppercase text-white fw-900"><Link to="/" className='text-white text-decoration-none m-0 p-0'>Home</Link></li>}
              {
                navItems && navItems.map((item,ind)=>{
                  return <li className="align-self-center nav-item btn text-uppercase text-white fw-900"><a className='text-white text-decoration-none m-0 p-0' target={(item.link.includes('brewerybeats') || item.link.includes('brewerymix')) && '_blank'} href={item.link}>{item.tab}</a></li>
                })
              }
              {/* <li className="align-self-center nav-item btn text-uppercase text-white fw-900"><a className='text-white text-decoration-none m-0 p-0' href="#studios">Studios</a></li>
              <li className="align-self-center nav-item btn text-uppercase text-white fw-900"><a className='text-white text-decoration-none m-0 p-0' href="#clients">Clients</a></li>
              <li className="align-self-center nav-item btn text-uppercase text-white fw-900"><a className='text-white text-decoration-none m-0 p-0' href="#engineers">Engineers</a></li>
              <li className="align-self-center nav-item btn text-uppercase text-white fw-900"><a className='text-white text-decoration-none m-0 p-0' href="#services">Services</a></li>
              <li className="align-self-center nav-item btn text-uppercase text-white fw-900"><a className='text-white text-decoration-none m-0 p-0' target='_blank' href="https://www.BREWERYBEATS.COM">Beats</a></li>
              <li className="align-self-center nav-item btn text-uppercase text-white fw-900"><a className='text-white text-decoration-none m-0 p-0' target='_blank' href="https://www.BREWERYMIX.COM">Mixings</a></li> */}
              <li className="align-self-center nav-item btn text-uppercase btn-outline-warning rounded-0 fw-900 p-2"><button onClick={()=>{setshowBookingPopup(true)}} className='text-warning fw-bold btn p-0 text-uppercase'>Booking</button></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
