import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import axios from "axios";
import { api_password, api_url, api_username, storage_url } from "./Data";

const Studio = () => {
    const [studios, setStudios] = useState([]);
    const [activeId, setactiveId] = useState(1)
    const [showSlider, setshowSlider] = useState(true)
    const [dataLoaded, setdataLoaded] = useState(false)
    const [loader, setloader] = useState(true)

    useEffect(() => {
        loadApi();
        setTimeout(() => {
            if(window.innerWidth < 500){
                document.querySelector('.bottomSlider .slick-track').style.transform = 'unset'
            }
        }, 3000);
    }, []);

    const loadApi = () => {
        axios.post(api_url + "studios_list", { username: api_username, password: api_password })
            .then((res) => {
                setStudios(res.data);
                setTimeout(() => {
                    setdataLoaded(true)
                }, 500);
            });
    };

    function nextClick() {
        document.querySelector('button.slick-arrow.slick-next').click()
    }
    function prevClick() {
        document.querySelector('button.slick-arrow.slick-prev').click()
    }
    function bs_nextClick() {
        document.querySelector('.bottomSlider button.slick-arrow.slick-next').click()
    }
    function bs_prevClick() {
        document.querySelector('.bottomSlider button.slick-arrow.slick-prev').click()
    }
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        // autoplay:true
    };
    var settings2 = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    infinite: false,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots:false,
                }
            },
        ]
    };

    const setSlide = (id) => {
        setshowSlider(false)
        setactiveId(id)
        setTimeout(() => {            
            setshowSlider(true)
        }, 50);
    }

    return (
        <>
            <div className="position-relative" id="studios">
                <div className="slider-wrap mainSlider studio position-relative">
                    {dataLoaded && <>
                    <button onClick={prevClick} className="border-0 position-absolute start-0 opacity top-0 z-index-999 text-white p-4"><i class="fs-2 fa-solid fa-angles-left"></i></button>
                    <button onClick={nextClick} className="border-0 position-absolute end-0 opacity top-0 z-index-999 text-white p-4"><i class="fs-2 fa-solid fa-angles-right"></i></button>
                    </>}
                    {
                    showSlider ? <Slider {...settings}>
                        {studios && studios.filter(i => i.id == activeId).map((item, index) => {
                            return item.slider.map((i, subIndex) => {
                                return <img key={subIndex} src={storage_url + i.img} alt="slider image" className="img-fluid customHeight d-100vh" />;
                            });
                        })}
                    </Slider>
                    :
                    <div className="d-100vh position-relative d-grid place-content-center">
                        <div className='text-white fw-bold fs-3 loading'></div> 
                    </div>
                    }
                </div>
                <div className="position-md-absolute col-md-8 mx-auto bottom-0 start-50 d-translate-middle-x">
                    <div className="bottomSlider position-relative">
                        { dataLoaded && 
                        <>
                        <button onClick={() => { bs_prevClick('bottomSlider') }} className=" border-0 position-absolute bg-transparent text-black top-50 translate-middle-y z-index-max btnLeft"><i class="fs-2 fa-solid fa-angles-left"></i></button>
                        <button onClick={() => { bs_nextClick('bottomSlider') }} className=" border-0 position-absolute bg-transparent text-black top-50 translate-middle-y z-index-max btnRight"><i class="fs-2 fa-solid fa-angles-right"></i></button>
                        </>
                        }
                        <Slider {...settings2}>
                            {studios && studios.map((std, index) => {
                                return (
                                    <div className={`card border-0 cursor-pointer vertical-middle hover-dark`} key={index} onClick={() => { setSlide(std.id) }}>
                                        <img src={storage_url + std.slider[0].img} alt="" className={`img-fluid ${std.id == activeId && 'brightness-0-3_off'}`} />
                                        <div className={`w-sm-100 text-center position-absolute start-50 top-50 translate-middle text-white w-100 h-100 d-grid place-content-center p-2  ${std.id != activeId && 'opacity'} ${std.id == activeId && 'bg-op-50-yellow'} `}>
                                            <h3 className="fw-bold ws-nowrap">{std.name}</h3>
                                            <h5>{std.category.name}</h5>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Studio