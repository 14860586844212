import { useState } from "react";


import { assets_url } from "./Config";
import Booking from "./components/Booking";
import Client from "./components/Client";
import Engineers from "./components/Engineers";
import HeroSection from "./components/HeroSection";
import Maps from "./components/Maps";
import Service from "./components/Service";
import Socials from "./components/Socials";
import Studio from "./components/Studio";
import Tour from "./components/Tour";
import Footer from "./components/partials/Footer";
import BookingPopup from "./components/BookingPopup";

function Home() {
  const [showBookingPopup, setshowBookingPopup] = useState(false)
  return (
    <div className="App">
      
      <HeroSection setshowBookingPopup={setshowBookingPopup}/>
      <Socials/>
      <Studio/>
      <Client/>
      <Engineers/>
      <Service setshowBookingPopup={setshowBookingPopup}/>
      <Booking setshowBookingPopup={setshowBookingPopup}/>
      <Maps/>
      <Tour/>
      <Footer/>
      { showBookingPopup && <BookingPopup setshowBookingPopup={setshowBookingPopup}/>}
    </div>
  );
}

export default Home;
