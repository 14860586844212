import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { api_password, api_url, api_username } from './Data';
import Typed from 'typed.js';

const Client = () => {
    const [Client, setClient] = useState([])


    useEffect(() => {
        axios.post(api_url + "clients_list", { username: api_username, password: api_password }).then((res) => {
            setClient(res.data)
        })

    }, [])
    // useEffect(() => {
    //     function shuffleText(textArray, elementId, delay) {
    //         const element = document.getElementById(elementId);
    //         let currentIndex = 0;

    //         setInterval(() => {
    //             currentIndex = (currentIndex + 1) % textArray.length;
    //             element.textContent = textArray[currentIndex];
    //         }, delay);
    //     }

    //     const namesArray = Client && Client.map(item => item.name) || [];
    //     const delay = 400; 
    //     shuffleText(namesArray, 'clientsName', delay);
    // }, [Client])

    const strings = Client?.map(item => item.name) || [];
  const speed = 100;
  const [index, setIndex] = useState(0);
  const [text, setText] = useState('');

  useEffect(() => {
    if (strings.length === 0) {
        setText(''); // If strings array is empty, clear the text
        return;
    }

    const timer = setTimeout(() => {
        const nextIndex = (index + 1) % strings.length; // Calculate the next index
        setText(strings[nextIndex]); // Set text to the next string
        setIndex(nextIndex); // Update the index
    }, speed);

    return () => clearTimeout(timer);
}, [index, strings, speed]);

  useEffect(() => {
    setIndex(0); // Reset index when Client changes
  }, [Client]);





    return (
        <>
            <div className="clientSection" id='clients'>
                <div className="container h-100 d-grid place-content-center">
                    <div className="container-fluid clientWidth">
                        <div className="row h-100 my-auto mx-0">
                            <div className="col-md-12 text-center">
                                <h4 className='fw-light p-color fs-48 font-abel mb-0 pt-3 text-uppercase'>Clients</h4>
                                <h2 className='fw-900 text-white fs-96 my-md-5 font-montserrat ws-nowrap text-uppercase'>&nbsp;<span id='clientsName'>{text}</span></h2>
                            </div>
                            <ul className='list-unstyled fs-24 column-4 text-center'>
                                {
                                    Client && Client.map((item, i) => {
                                        return <li className='font-abel text-capitalize my-1 text-white'>{item.name}</li>
                                    })
                                }
                            </ul>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Client